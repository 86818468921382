import styled from 'styled-components'

export const AlreadyRegisterStyled = styled.div`
	padding: 20px;

	h2 {
		margin-top: 0;
	}

	.col-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

export const Button = styled.a`
	display: block;
	background: ${props => props.theme.colors.primary};
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	margin: 40px 0;
	width: 100%;
	border: none;
	color: ${props => props.theme.colors.white};
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	&:focus,
	&:hover {
		background: ${props => props.theme.colors.primary_hover};
		outline: none;
	}

	width: 200px;

	text-decoration: none;
	font-size: 16px;
	cursor: pointer;
`
