import axios from '@monorepo-amais/commons/utils/base-axios'

export default async cpf => {
	cpf = cpf.replace(/\./g, '')
	cpf = cpf.replace(/-/g, '')

	const response = await axios.get(`/cadastro-medico/v1/medico?cpf=${cpf}`)

	return response.status === 200 ? response.data : null
}
