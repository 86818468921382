import axios from 'axios'

export const getCep = async cep => {
	/**
	 * Give a only number cep string
	 * https://viacep.com.br/
	 */
	const instance = axios.create({
		baseURL: 'https://viacep.com.br'
	})
	const response = instance.get(`/ws/${cep}/json`)
	return response
}
