import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import CadastroMedicoComponent from '@monorepo-amais/commons/components/cadastroMedico/cadastroMedico'

/**
 * Componente que desenha o formulario para cadastro medico
 * @param {object} data - dados do graphql
 */
const CadastroMedico = ({ data }) => <CadastroMedicoComponent Layout={Layout} {...data}></CadastroMedicoComponent>

export const query = graphql`
	query {
		cosmicjsInformacoesEstaticas(slug: { eq: "cadastro-medico" }) {
			metadata {
				seo {
					descricao
					titulo
					imagem {
						imgix_url
						url
					}
				}
				emails {
					email
				}
				imagem_do_banner {
					url
					imgix_url
				}
				title
				conteudo
				informacoes_footer
			}
		}
		regionais: cosmicjsInformacoesEstaticas(title: { eq: "Regionais" }) {
			metadata {
				telefones {
					estado
					telefone
					sigla
				}
			}
		}
		especialidades: allCosmicjsEspecialidades {
			nodes {
				slug
				title
			}
		}
	}
`

export default CadastroMedico
