import Modal from './Modal'

import React from 'react'

import { AlreadyRegisterStyled, Button } from './already-register-modal-styled'

const getBrandLink = brand => {
	switch (brand) {
		case 'amaissp' || 'amais-df' || 'amaispe' || 'amaispi' || 'amaispr':
			return 'https://medicos.amaissaude.com.br/'
		case 'diagnoson':
			return 'https://medicos.diagnoson.com.br/'
		case 'labs-amais':
			return 'https://medicos.labsamais.com.br/'
		case 'irn':
			return 'https://medicos.institutoderadiologia.com.br/'
		default:
			return 'https://medicos.amaissaude.com.br/'
	}
}

export default props => (
	<Modal onClose={props.onClose} opened={props.show}>
		<AlreadyRegisterStyled>
			<div className='grid col-12'>
				<div className='col-wrapper'>
					<h2>Atenção</h2>
					<div>CPF já cadastrado! Para fazer login ou recuperar sua senha clique no botão abaixo.</div>
					{/* <Button href={getBrandLink(process.env.GATSBY_COSMIC_BUCKET)} onClick={props.onClose}> */}
					<Button
						onClick={() => {
							window.open(getBrandLink(process.env.GATSBY_COSMIC_BUCKET), '_blank')
							props.onClose()
						}}
					>
						Entrar como médico
					</Button>
				</div>
			</div>
		</AlreadyRegisterStyled>
	</Modal>
)
