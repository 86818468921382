import styled from 'styled-components'

export const AdviceCrmRj = styled.div`
	background-color: #fef6e7;
	width: auto;
	padding: 12px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;

	display: flex;
	span {
		/* font-family: 'KyrialSansPro'; */
		font-size: 12px;
		line-height: 18px;
		color: #595959;
		padding: 3px;
	}

	img {
		height: 100%;
		display: flex;
		align-items: flex-start;
		padding-top: 4px;
		padding-right: 10px;
		padding-left: 10px;
	}
`
