import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Select from 'react-select'
import { Input } from '../Inputs'
import { sendMedicRegister } from '../../api/email'
import InputMask from 'react-input-mask'
import validator from 'validator'
import { useFormik } from 'formik'
import { isValidDate } from '../../utils/date'
import { Loading } from '../Search'
import isValidCPF from '../../utils/cpf'

import { getCep } from '../../utils/via-cep.datasource'
import { BreadcrumbAuto, MainContainer } from '../Blocks'
import Mensagem from '../Mensagem/Mensagem'
import SEO from '../seo'
import { MainTitle } from '../Typography'

import getCpfMedico from '../../api/cpf-medico'
import CryptoJS from 'crypto-js'

import AlreadyRegisterModal from '../../components/Modal/already-register-modal'
import themeObject from '../../theme'
import { ufs } from '../../utils/constants'

import { AdviceCrmRj } from './cadastro-medico-style'
import AttentionIcon from './AttentionIcon.svg'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const ImageTop = styled.img`
	width: 100%;
	object-fit: contain;
	border-radius: 5px;
	margin: 20px 0;
`

const FormTitle = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.31;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin: 0;
`

const FormTitleIntro = styled.span`
	font-size: 24px;
	font-weight: bold;
`

const AmaisText = styled.span`
	strong {
		font-weight: bold;
		color: ${props => props.theme.colors.primary};
	}
`

const Form = styled.form`
	width: 70%;
	margin: 20px auto;

	@media (max-width: 768px) {
		width: 100%;
	}
`

const SelectLabel = styled.span`
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: #b9b9b9;
`

const Especiality = styled.div`
	width: 48%;
	display: inline-block;
	margin-right: 3%;
	@media (max-width: 1024px) {
		width: 100%;
		margin-right: 0px;
	}
`

const State = styled.div`
	width: 23%;
	display: inline-block;
	margin-right: 3%;

	@media (max-width: 1024px) {
		margin-top: 40px;
		width: 100%;
		margin-right: 0px;
	}
`

const Crm = styled.div`
	vertical-align: bottom;
	width: 23%;
	display: inline-block;
	@media (max-width: 1024px) {
		margin-top: 40px;
		width: 100%;
	}
`

const FullName = styled.div`
	vertical-align: bottom;
	width: 100%;
	display: inline-block;
	margin-top: 40px;

	display: flex;
	flex-direction: row;
	position: relative;
	align-items: flex-end;

	.titulo {
		width: 150px;
		margin-right: 30px;
	}

	.ctn-name {
		width: 100%;
		div {
			width: auto;
		}
	}
`

const RG = styled.div`
	vertical-align: bottom;
	width: 35%;
	display: inline-block;
	margin-top: 40px;
	margin-right: 3%;
	@media (max-width: 1024px) {
		width: 100%;
		margin-right: 0px;
	}
`

const CPF = styled.div`
	vertical-align: bottom;
	width: 35%;
	display: inline-block;
	margin-top: 40px;
	margin-right: 3%;
	@media (max-width: 1024px) {
		width: 100%;
		margin-right: 0px;
	}
`

/**
 * Componente do input com o tratamento de mascara para cpf
 * @param {object} cpfProps - objecto enviado pelo formik apos atualizacao
 */
const InputCpf = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='999.999.999-99'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('cpf')}
	>
		{inputProps => (
			<Input
				id='cpf'
				value={value}
				error={error && touched}
				onChange={onChange}
				label='CPF'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
				{...inputProps}
			/>
		)}
	</InputMask>
)

const BirthDate = styled.div`
	vertical-align: bottom;
	width: 24%;
	display: inline-block;
	margin-top: 40px;
	@media (max-width: 1024px) {
		width: 100%;
	}
`

/**
 * Componente do input com o tratamento de mascara para data de nascimento
 * @param {object} birthDateProps - objecto enviado pelo formik apos atualizacao
 */
const InputBirthDate = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='99/99/9999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('birthDate')}
	>
		{inputProps => (
			<Input
				id='birthDate'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label='Data de Nascimento'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)

const Address = styled.div`
	vertical-align: bottom;
	width: 100%;
	display: inline-block;
	margin-top: 40px;

	display: flex;
	flex-direction: row;

	position: relative;

	div {
		&.half {
			width: 50%;
		}
		&:first-child {
			margin-right: 30px;
		}
	}

	@media (max-width: 992px) {
		flex-direction: column;

		div {
			&.half {
				width: 100%;

				&:first-child {
					margin-bottom: 30px;
				}
			}
			&:first-child {
				margin-right: 0;
				// margin-bottom: 25px;
			}
		}
	}
`

const Phone = styled.div`
	width: 23%;
	display: inline-block;
	margin-right: 3%;
	margin-top: 40px;
	@media (max-width: 1024px) {
		width: 100%;
		margin-right: 0px;
	}
`

/**
 * Componente do input com o tratamento de mascara para telefone
 * @param {object} phoneProps - objecto enviado pelo formik apos atualizacao
 */
const InputPhone = ({ value, error, onChange, onBlur, touched }) => (
	<InputMask
		mask='(99) 9999-9999'
		maskChar={null}
		alwaysShowMask={false}
		value={value}
		error={error && touched}
		onChange={onChange}
		onBlur={() => onBlur('phone')}
	>
		{inputProps => (
			<Input
				id='phone'
				value={value}
				error={error && touched}
				onChange={onChange}
				{...inputProps}
				label='Telefone'
				required={true}
				lineHeight='15px'
				fontSize='16px'
				color='#b9b9b9'
				inputFontSize='14px'
				inputColor={props => props.theme.colors.gray66}
				inputPadding='padding: 0px 10px 11px 0px;'
				inputBorderBottom='1px solid #ddd'
				inputMarginTop='-34px'
				inputBackground='transparent'
			/>
		)}
	</InputMask>
)

// const Cell = styled.div`
// 	width: 23%;
// 	display: inline-block;
// 	margin-right: 3%;
// 	margin-top: 40px;
// 	@media (max-width: 1024px) {
// 		width: 100%;
// 		margin-right: 0px;
// 	}
// `

/**
 * Componente do input com o tratamento de mascara para celular
 * @param {object} cellProps - objecto enviado pelo formik apos atualizacao
 */
// const InputCell = ({ value, error, onChange, onBlur, touched }) => (
// 	<InputMask
// 		mask='(99) 99999-9999'
// 		maskChar={null}
// 		alwaysShowMask={false}
// 		value={value}
// 		error={error && touched}
// 		onChange={onChange}
// 		onBlur={() => onBlur('cell')}
// 	>
// 		{inputProps => (
// 			<Input
// 				id='cell'
// 				value={value}
// 				error={error && touched}
// 				onChange={onChange}
// 				{...inputProps}
// 				label='Celular'
// 				required={true}
// 				lineHeight='15px'
// 				fontSize='16px'
// 				color='#b9b9b9'
// 				inputFontSize='14px'
// 				inputColor={props => props.theme.colors.gray66}
// 				inputPadding='padding: 0px 10px 11px 0px;'
// 				inputBorderBottom='1px solid #ddd'
// 				inputMarginTop='-34px'
// 				inputBackground='transparent'
// 			/>
// 		)}
// 	</InputMask>
// )

const Email = styled.div`
	width: 48%;
	display: inline-block;
	margin-top: 40px;
	@media (max-width: 1024px) {
		width: 100%;
	}
`

const Observation = styled.p`
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin-top: 31px;
`

//TODO
const ButtonDiv = styled.div`
	margin-top: 30px;
	text-align: center;
`

const Button = styled.button`
	border-radius: 100px;
	width: 344px;
	height: 56px;
	border: none;
	background-color: ${props => props.theme.colors.primary};
	color: ${theme('colors.white')};
	/* height: 26.8px; */
	padding: 0.5rem 1rem;
	white-space: pre-wrap;
	cursor: pointer;

	&:not([disabled]):hover {
		background: ${theme('colors.activeHoverBlue')};
		border-color: #2eb0e4;
	}

	&:not([disabled]):active {
		background: ${theme('colors.activeHoverBlue')};
		border-color: #2eb0e4;
	}
	@media (max-width: 767.98px) {
		width: 100%;
	}
`

const Footer = styled.div`
	margin: 20px 0px;
	width: 96%;
	background-color: ${props => props.theme.colors.grayf8};
	padding: 20px;
	@media (max-width: 767.98px) {
		width: 89%;
	}
	@media (min-width: 768px) and (max-width: 991.98px) {
		width: 95%;
	}
`

const FooterText = styled.p`
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.gray66};
	margin: 0px;
`

const InvalidInput = styled.span`
	position: absolute;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: left;
	color: #ff0000;
	margin: 5px 0px 0px;
`

const Required = styled.span`
	color: ${props => props.theme.colors.primary};
`

const CustomSelect = styled(Select)`
	border-bottom: ${({ error }) => error && '1px solid #f00'};
`

const selectStyles = {
	indicatorSeparator: base => ({
		...base,
		background: 'none'
	}),
	dropdownIndicator: base => ({
		...base,
		svg: {
			fill: themeObject.colors.primary
		}
	}),
	control: base => ({
		...base,
		borderBotton: '1px',
		borderTop: '0px',
		borderLeft: '0px',
		borderRight: '0px',
		borderRadius: '0px',
		backgroundColor: themeObject.colors.white,
		// borderColor: theme.colors.graye2,
		boxShadow: 'none',
		'&:hover': {
			borderColor: themeObject.colors.graye2
		}
	}),
	menu: base => ({
		...base,
		marginTop: 0,
		textAlign: 'center',
		wordWrap: 'break-word',
		overflow: 'hidden'
	}),
	menuList: base => ({
		...base,
		padding: 0
	}),
	singleValue: base => ({
		...base,
		color: `${props => props.theme.colors.gray66}`,
		fontSize: '14px',
		lineHeight: '19px'
	}),
	placeholder: base => ({
		...base,
		color: `${props => props.theme.colors.gray66}`,
		fontSize: '14px',
		lineHeight: '19px',
		left: '0px'
	}),
	option: (base, state) => ({
		...base,
		fontSize: '14px',
		lineHeight: '19px',
		backgroundColor: `${props => (state.isSelected ? props.theme.colors.primary : props.theme.colors.white)}`,
		color: `${props => (state.isSelected ? props.theme.colors.white : props.theme.colors.gray66)}`,
		':active': {
			backgroundColor: state.isSelected ? themeObject.colors.primary : themeObject.colors.white,
			color: `${props => (state.isSelected ? props.theme.colors.white : props.theme.colors.gray66)}`
		},
		'&:hover': {
			backgroundColor: themeObject.colors.primary,
			color: themeObject.colors.white
		}
	})
}

function hideMessage(setShowMessage, resetForm) {
	setShowMessage(false)

	resetForm()
}

let specialities = []

let states = ufs

const CadastroMedicoComponent = ({ Layout, cosmicjsInformacoesEstaticas, regionais, especialidades }) => {
	const [loading, setLoading] = useState(false)
	const [firstLoad, setFirstLoad] = useState(false)
	const [showMessage, setShowMessage] = useState(false)
	const [messageContent, setMessageContent] = useState('')
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [showModalAlreadyRegister, setShowModalAlreadyRegister] = useState(false)

	const formik = useFormik({
		initialValues: {
			speciality: null,
			uf: null,
			crm: '',
			name: '',
			rg: '',
			cpf: '',
			birthDate: '',
			address: '',
			phone: '',
			cell: '',
			email: '',
			cep: '',
			titulo: ''
		},
		validate: values => {
			const err = {}
			const message = 'Campo obrigatório'
			const crmInvalido = 'CRM inválido. Remova o primeiro zero'
			const invalid = 'Campo inválido'
			const phoneCheck = values.phone
				.replace('(', '')
				.replace(')', '')
				.replace(' ', '')
				.replace('-', '')

			// const cellCheck = values.cell
			// 	.replace('(', '')
			// 	.replace(')', '')
			// 	.replace(' ', '')
			// 	.replace('-', '')

			if (!values.speciality) err.speciality = message
			if (!values.uf) err.uf = message
			if (!values.crm) err.crm = message
			if (values.crm < 0) err.crm = invalid
			if (!values.titulo) err.titulo = message
			if (!values.name) err.name = message
			if (!values.rg) err.rg = message
			if (!values.cpf) err.cpf = message
			if (
				!isValidCPF(
					values.cpf
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				) &&
				values.cpf
			)
				err.cpf = invalid
			if (!values.birthDate) err.birthDate = message
			if (!isValidDate(values.birthDate) && values.birthDate) err.birthDate = invalid
			if (!values.cep) err.cep = message
			if (!values.address) err.address = message
			if (!phoneCheck) err.phone = message
			if (phoneCheck && phoneCheck.length < 10) err.phone = invalid
			// if (!cellCheck) err.cell = message
			// // if (cellCheck && cellCheck.length < 10) err.cell = invalid
			if (values.uf.value === 'RJ' && values.crm.substring(0, 1) === '0') err.crm = crmInvalido``
			if (!values.email) err.email = message
			if (!validator.isEmail(values.email) && values.email) err.email = invalid

			return err
		},
		onSubmit: async values => {
			const medico = {
				marca: process.env.GATSBY_MS_MARCA_PARAM,
				titulo: values.titulo.label,
				nome: values.name,
				dataNascimento: values.birthDate,
				rg: values.rg,
				cpf: values.cpf,
				especialidade: values.speciality.label,
				crm: values.crm,
				email: values.email,
				telefone: values.phone,
				// endereco: 'formdata.commercialAddress',
				// numero: 'formdata.addressNumber',
				// complemento: 'formdata.addressSuplement ||',
				cep: values.cep,
				estado: values.uf ? values.uf.label : '',
				// bairro: 'formdata.addressDistrict',
				// cidade: 'formdata.city',
				enderecoCompleto: values.address
				//	cell: values.cell,
			}
			setLoading(true)

			await sendMedicRegister(medico)
				.then(response => {
					if (response.data.statusCode === 200) {
						setSubmitSuccess(true)
						setMessageContent(
							'Seu cadastro foi efetuado com sucesso. Em breve enviaremos um link para cadastro de senha.'
						)
					} else {
						setSubmitSuccess(false)
						setMessageContent(response.data.body)
					}
				})
				.catch(e => {
					setSubmitSuccess(false)
					setMessageContent(
						'Infelizmente não foi possível concluir seu cadastro, aguarde alguns instantes e tente novamente!'
					)
				})

			setLoading(false)
			setShowMessage(true)
		}
	})

	const handleChangeCep = cep => {
		if (cep.length >= 8)
			getCep(cep).then(res => {
				formik.values.address = `${res.data.logradouro}, ${res.data.bairro}, ${res.data.localidade}, Número: ${res.data.complemento}`
			})
	}

	React.useEffect(() => {
		handleChangeCep(formik.values.cep)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.cep])

	const handleBlur = field => {
		formik.setTouched({ ...formik.touched, [field]: true })
	}

	const phoneProps = {
		value: formik.values.phone,
		error: formik.errors.phone,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.phone
	}

	// const cellProps = {
	// 	value: formik.values.cell,
	// 	error: formik.errors.cell,
	// 	onChange: formik.handleChange,
	// 	onBlur: handleBlur,
	// 	touched: formik.touched.cell
	// }

	const handleCpfchange = async e => {
		e.persist()
		formik.handleChange(e)

		const cpf = e.target.value

		const validate = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/

		if (validate.test(cpf)) {
			const cpfData = await getCpfMedico(cpf)
			const isRegisteredMedic = CryptoJS.AES.decrypt(cpfData.data, 'RCMxMjMyMDEyRkxFVVJZUDRzc1dAb3Jk').toString(
				CryptoJS.enc.Utf8
			)

			if (isRegisteredMedic === 'true') {
				setShowModalAlreadyRegister(true)
			}
		}
	}

	const cpfProps = {
		value: formik.values.cpf,
		error: formik.errors.cpf,
		onChange: handleCpfchange,
		onBlur: handleBlur,
		touched: formik.touched.cpf
	}

	const birthDateProps = {
		value: formik.values.birthDate,
		error: formik.errors.birthDate,
		onChange: formik.handleChange,
		onBlur: handleBlur,
		touched: formik.touched.birthDate
	}

	if (!firstLoad) {
		especialidades.nodes
			.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
			.map(especialidade => specialities.push({ value: especialidade.slug, label: especialidade.title }))
		// regionais.metadata.telefones.map(regiao => states.push({ value: regiao.sigla, label: regiao.estado }))
		setFirstLoad(true)
	}

	return (
		<Layout isClient={false}>
			<SEO
				title={cosmicjsInformacoesEstaticas.metadata.seo.titulo}
				description={cosmicjsInformacoesEstaticas.metadata.seo.descricao}
				image={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.seo.imagem.imgix_url)}
			/>

			{loading && <Loading />}

			<MainContainer needPaddingZeroMax1024={false}>
				{showModalAlreadyRegister && (
					<AlreadyRegisterModal
						show={showModalAlreadyRegister}
						onClose={async () => {
							window.location.reload()
						}}
					/>
				)}

				<MediaQuery minDeviceWidth={1224}>
					<BreadcrumbAuto title='Cadastro Médico' />
				</MediaQuery>

				<MainTitle>Cadastro Médico</MainTitle>

				<ImageTop src={imgixUrlOptimizerAuto(cosmicjsInformacoesEstaticas.metadata.imagem_do_banner.imgix_url)} />

				<FormTitle>
					<FormTitleIntro>{cosmicjsInformacoesEstaticas.metadata.title}</FormTitleIntro>
					<br />
					<AmaisText dangerouslySetInnerHTML={{ __html: cosmicjsInformacoesEstaticas.metadata.conteudo }} />
				</FormTitle>

				<Form onSubmit={formik.handleSubmit}>
					<Especiality>
						<SelectLabel>
							Especialidade
							<Required>*</Required>
						</SelectLabel>

						<CustomSelect
							styles={selectStyles}
							options={specialities}
							placeholder='Selecione'
							value={formik.values.speciality}
							onChange={speciality => formik.setFieldValue('speciality', speciality)}
							error={formik.touched.speciality && formik.errors.speciality}
							onBlur={() => handleBlur('speciality')}
							inputId='select-input-medic-specialty'
							id='select-medic-specialty'
						/>

						{formik.touched.speciality && formik.errors.speciality && (
							<InvalidInput>{formik.errors.speciality}</InvalidInput>
						)}
					</Especiality>

					<State noSelect={states.length === 1}>
						<SelectLabel>
							UF CRM
							<Required>*</Required>
						</SelectLabel>

						<CustomSelect
							styles={selectStyles}
							options={states}
							placeholder='Selecione'
							value={formik.values.uf}
							onChange={uf => formik.setFieldValue('uf', uf)}
							error={formik.touched.uf && formik.errors.uf}
							onBlur={() => handleBlur('uf')}
							id='select-medic-state'
							inputId='select-input-medic-state'
						/>
						{formik.touched.uf && formik.errors.uf && <InvalidInput>{formik.errors.uf}</InvalidInput>}
					</State>

					<Crm noSelect={states.length === 1}>
						<Input
							id='crm'
							label='CRM'
							required={true}
							lineHeight='15px'
							fontSize='16px'
							color='#b9b9b9'
							inputFontSize='14px'
							inputColor={props => props.theme.colors.gray66}
							inputPadding='padding: 0px 10px 11px 0px;'
							inputBorderBottom='1px solid #ddd'
							inputMarginTop='-34px'
							inputBackground='transparent'
							onChange={formik.handleChange}
							value={formik.values.crm}
							error={formik.touched.crm && formik.errors.crm}
							onBlur={() => handleBlur('crm')}
						/>

						{formik.touched.crm && formik.errors.crm && <InvalidInput>{formik.errors.crm}</InvalidInput>}
					</Crm>
					{formik.values.uf?.value === 'RJ' && (
						<AdviceCrmRj>
							<img src={AttentionIcon} alt='atenção' />
							<span>
								Adicione “52” na frente do CRM e remova o primeiro zero, se houver. Se o seu CRM já começa com “52”, é
								só digitar normalmente
							</span>
						</AdviceCrmRj>
					)}
					<FullName>
						<div className='titulo'>
							<SelectLabel>
								Titulo
								<Required>*</Required>
							</SelectLabel>

							<CustomSelect
								styles={selectStyles}
								options={[
									{ value: 'Dr', label: 'Dr' },
									{ value: 'Dra', label: 'Dra' }
								]}
								placeholder='Selecione'
								value={formik.values.titulo}
								onChange={titulo => formik.setFieldValue('titulo', titulo)}
								error={formik.touched.speciality && formik.errors.titulo}
								onBlur={() => handleBlur('titulo')}
								inputId='select-input-medic-titulo'
								id='select-medic-titulo'
							/>

							{formik.touched.titulo && formik.errors.titulo && <InvalidInput>{formik.errors.titulo}</InvalidInput>}
						</div>

						<div className='ctn-name'>
							<Input
								id='name'
								label='Nome completo'
								required={true}
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={props => props.theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								onChange={formik.handleChange}
								value={formik.values.name}
								error={formik.touched.name && formik.errors.name}
								onBlur={() => handleBlur('name')}
							/>

							{formik.touched.name && formik.errors.name && <InvalidInput>{formik.errors.name}</InvalidInput>}
						</div>
					</FullName>

					<RG>
						<Input
							id='rg'
							label='RG'
							required={true}
							lineHeight='15px'
							fontSize='16px'
							color='#b9b9b9'
							inputFontSize='14px'
							inputColor={props => props.theme.colors.gray66}
							inputPadding='padding: 0px 10px 11px 0px;'
							inputBorderBottom='1px solid #ddd'
							inputMarginTop='-34px'
							inputBackground='transparent'
							maxLength='10'
							onChange={formik.handleChange}
							value={formik.values.rg}
							error={formik.touched.rg && formik.errors.rg}
							onBlur={() => handleBlur('rg')}
						/>

						{formik.touched.rg && formik.errors.rg && <InvalidInput>{formik.errors.rg}</InvalidInput>}
					</RG>

					<CPF>
						<InputCpf {...cpfProps} />
						{formik.touched.cpf && formik.errors.cpf && <InvalidInput>{formik.errors.cpf}</InvalidInput>}
					</CPF>

					<BirthDate>
						<InputBirthDate {...birthDateProps} />

						{formik.touched.birthDate && formik.errors.birthDate && (
							<InvalidInput>{formik.errors.birthDate}</InvalidInput>
						)}
					</BirthDate>

					<Address>
						<div className='half'>
							<Input
								id='cep'
								mask='99999-999'
								label='CEP'
								required={true}
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={props => props.theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								onChange={formik.handleChange}
								value={formik.values.cep}
								error={formik.touched.cep && formik.errors.cep}
								onBlur={() => handleBlur('cep')}
							/>
							{formik.touched.cep && formik.errors.cep && <InvalidInput>{formik.errors.cep}</InvalidInput>}
						</div>

						<div className='half'>
							<Input
								id='address'
								label='Endereço comercial completo'
								required={true}
								lineHeight='15px'
								fontSize='16px'
								color='#b9b9b9'
								inputFontSize='14px'
								inputColor={props => props.theme.colors.gray66}
								inputPadding='padding: 0px 10px 11px 0px;'
								inputBorderBottom='1px solid #ddd'
								inputMarginTop='-34px'
								inputBackground='transparent'
								onChange={formik.handleChange}
								value={formik.values.address}
								error={formik.touched.address && formik.errors.address}
								onBlur={() => handleBlur('address')}
							/>

							{formik.touched.address && formik.errors.address && <InvalidInput>{formik.errors.address}</InvalidInput>}
						</div>
					</Address>

					<Phone>
						<InputPhone {...phoneProps} />

						{formik.touched.phone && formik.errors.phone && <InvalidInput>{formik.errors.phone}</InvalidInput>}
					</Phone>

					{
						// 	<Cell>
						// 	<InputCell {...cellProps} />
						// 	{formik.touched.cell && formik.errors.cell && <InvalidInput>{formik.errors.cell}</InvalidInput>}
						// </Cell>
					}

					<Email>
						<Input
							id='email'
							label='E-mail'
							required={true}
							lineHeight='15px'
							fontSize='16px'
							color='#b9b9b9'
							inputFontSize='14px'
							inputColor={props => props.theme.colors.gray66}
							inputPadding='padding: 0px 10px 11px 0px;'
							inputBorderBottom='1px solid #ddd'
							inputMarginTop='-34px'
							inputBackground='transparent'
							onChange={formik.handleChange}
							value={formik.values.email}
							error={formik.touched.email && formik.errors.email}
							onBlur={() => handleBlur('email')}
						/>

						{formik.touched.email && formik.errors.email && <InvalidInput>{formik.errors.email}</InvalidInput>}
					</Email>

					<Observation>
						<Required>*</Required> Campos Obrigatórios
					</Observation>

					<ButtonDiv>
						<Button id='button-submit-register' type='submit'>
							Cadastrar
						</Button>
					</ButtonDiv>

					<Footer>
						<FooterText>{cosmicjsInformacoesEstaticas.metadata.informacoes_footer}</FooterText>
					</Footer>
				</Form>
			</MainContainer>
			{showMessage && (
				<Mensagem
					closeMessage={() => hideMessage(setShowMessage, formik.resetForm)}
					textoBotao='OK'
					mensagem={messageContent}
					success={submitSuccess}
				/>
			)}
		</Layout>
	)
}

export default CadastroMedicoComponent
